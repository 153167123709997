import http from "./http";
import Service from "../Service";

export default class contractService extends Service {
    allContracts (payload) {
        let url = '/admin/contract?page='+payload.page
        if (payload.document) url += '&cnt_bling_cnpj='+payload.document
        if (payload.status) url += '&cnt_status='+payload.status
        if (payload.pendecie) url += '&cnt_contas_atrasada=0'
        return http.get(url, {headers: this.getHeaders()})
    }
    deleteContract (contract) {
        contract.cid_id = undefined
        contract.cli_id = undefined
        contract.cnt_bling_cnpj = undefined
        contract.cnt_contas_aberto = undefined
        contract.cnt_contas_atrasada = undefined
        contract.cnt_data_venc = undefined
        contract.cnt_id_contrato_bling = undefined
        contract.cnt_loja_id = undefined
        contract.cnt_numero_contrato = undefined
        contract.cnt_status = undefined
        contract.cnt_total_valor = undefined
        contract.cnt_ultima_att = undefined
        contract.est_id = undefined
        contract.for_id = undefined
        contract.loj_bairro = undefined
        contract.loj_cep = undefined
        contract.loj_cnpj = undefined
        contract.loj_complemento = undefined
        contract.loj_consolida = undefined
        contract.loj_dataCadastro = undefined
        contract.loj_descricao = undefined
        contract.loj_email = undefined
        contract.loj_endereco = undefined
        contract.loj_excluido = undefined
        contract.loj_id = undefined
        contract.loj_matriz = undefined
        contract.loj_nomeFantasia = undefined
        contract.loj_numeroLoja = undefined
        contract.loj_observacao = undefined
        contract.loj_razao = undefined
        contract.loj_status = undefined
        contract.loj_telefone = undefined
        return http.put('/admin/contract/',{...contract}, {headers: this.getHeaders()})
    }
    allContracts2 (status, page) {
        let url = '/admin/bills?per_page=15'
        if (status) url += '&bills_status=' + status
        if (page) url += '&page=' + page
        return http.get(url, {headers: this.getHeaders()})
    }
}