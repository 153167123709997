<template>
  <div class="contract-page">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Contractos
      </div>
    </div>
    <div class="page-title">
      Gestão de contratos
    </div>

    <div class="contract-page-tab-buttons-container">
      <button class="contract-page-tab-button" :class="getButtonClass(1)" style="margin-right: 5vw;"
        v-on:click="table = 1">
        <span class="material-icons-outlined" style="color: var(--primary-color)">description</span>
        Bling
      </button>
      <button class="contract-page-tab-button" :class="getButtonClass(2)" v-on:click="table = 2">
        <span class="material-icons-outlined" style="color: var(--primary-color)">receipt_long</span>
        Vindi
      </button>
    </div>

    <table1 v-if="table == 1" />
    <table2 v-else-if="table == 2" />

  </div>
</template>

<script>
import table1 from './table1.vue';
import table2 from './table2.vue';

export default {
  name: 'ContractPage',
  data() {
    return {
      table: 1
    }
  },
  components: {
    table1,
    table2
  },
  methods: {
    getButtonClass(tab) { if (tab == this.table) return 'contract-page-tab-button-activated' }
  }
}
</script>

<style>
.contract-page {
  font-size: 1vw;
  margin-right: 4em;
}

.page-route-select {
  display: flex;
  align-items: center;
}

.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}

.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}

.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}

.contract-page-tab-button {
  background-color: white;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  margin-right: 2vw;
  font-weight: 600;
  font-size: 1.3em;
  color: #202020;
  gap: 1vw;
}

.contract-page-tab-button:hover {
  border-radius: 8px 8px 8px 8px;
  background-color: #fdf0e9;
}

.contract-page-tab-button-activated {
  cursor: default;
  border-radius: 8px 8px 8px 8px;
  background-color: #fdf0e9;
}

.contract-page-tab-buttons-container {
  display: flex;
  margin-top: 2.5vw;
  border-bottom: 1px solid lightgray;
  padding-bottom: 2vh;
}
.monitor-icon {
  cursor: pointer;
  color: var(--primary-color);
}



@media only screen and (max-width: 900px) {
  .contract-page {
    font-size: 2vw;
  }
  .contract-page-tab-button{
    width: 40%;
}
.contract-page-tab-buttons-container{margin-top: 5vh;}
}

@media only screen and (max-width: 500px) {
  .contract-page {
    font-size: 3vw;
    margin-right: 5vw;
  }
}
</style>