<template>
  <div>
    <div class="contract-buttons">
      <StandardInput :placeholder="'Digite o CNPJ'" 
                     :title="'CNPJ'" 
                     :action="setDocument" 
                     :value="selectedDocument"
                     class="contract-document-input"
                     :type="'text'"/>
      <!-- <StandardInputV2 class="contract-document-input" :action="setDocument" :type="'text'" title="CNPJ" /> -->
      <StandardInputV2 :placeholder="'Selecione o status'" 
                       class="contract-status-input" 
                       :list="status" 
                       :action="setSelectedStatus" 
                       :value="selectedStatus"
                       :type="'select'"
                       title="Status" />
      <div class="contract-check-container" v-on:click="(pendecieOnly = !pendecieOnly)">
        <span class="material-icons-outlined contract-checked-icon" v-if="pendecieOnly">check_box</span>
        <span class="material-icons-outlined contract-unchecked-icon" v-else>check_box_outline_blank</span>
        <div class="contract-check-title">Apenas contratos com pendencia</div>
      </div>
      <StandardButton class="contract-clean-input" :action="clean" iconName="cancel" :text="'Limpar filtros'" />
    </div>
    <div class="page-table-header">
      <div class="page-table-header-text id-width">ID</div>
      <div class="page-table-header-text store-width">
        <div>Empresa</div>
        <div>CNPJ</div>
      </div>
      <div class="page-table-header-text name-width">Nome Fantasia</div>
      <div class="page-table-header-text pendencie-width">Titulos em atraso</div>
      <div class="page-table-header-text date-width">Data</div>
      <div class="page-table-header-text status-width">Status</div>
      <div class="page-table-header-text actions-width">Ações</div>
    </div>
    <div v-for="(contract, idx) in contracts" :key="idx" :class="getSpecialBackground(idx)"
      class="page-table-row-contracts">
      <div class="page-table-desc-column id-width">
        <div class="table1-mobile-header">ID</div>
        {{ contract.cnt_loja_id }}
      </div>
      <div class="page-table-desc-column store-width">
        <div class="table1-mobile-header">Empresa / CNPJ</div>
        <div>
          <!-- <div>{{ contract.loj_descricao }}</div> -->
          <div style="font-weight: bold;">{{ contract.loj_razao }}</div>
          <div v-if="contract.cnt_bling_cnpj">{{ contract.cnt_bling_cnpj | VMask('## ### ###/####-##') }}</div>
          <div v-else>--</div>
        </div>

      </div>
      <div class="page-table-desc-column name-width">
        <div class="table1-mobile-header">Nome Fantasia</div>
        {{ contract.loj_nomeFantasia }}
      </div>
      <div class="page-table-desc-column pendencie-width">
        <div class="table1-mobile-header">Titulos em atraso</div>
        <div v-if="(contract.cnt_contas_atrasada > 0)" class="contract-pendencies-icon">{{ contract.cnt_contas_atrasada }}
        </div>
        <div v-else>--</div>
      </div>
      <div class="page-table-desc-column date-width">
        <div class="table1-mobile-header">Data</div>
        {{ formatDate(contract.cnt_data_venc) }}
      </div>
      <div class="page-table-desc-column status-width">
        <div class="table1-mobile-header">Status</div>
        <div class="contract-status" :class="getStatusStyle(contract.cnt_status)">{{ contract.cnt_status }}</div>
      </div>
      <div class="page-table-desc-column actions-width">
        <div class="table1-mobile-header">Ações</div>
        <router-link style="font-size: inherit;" 
                     :to="{ 
                       name: 'monitors-page', 
                       params: { tab: 'cotacoes' }, 
                       query: { client_id: contract.cli_id, name: contract.loj_razao } 
                     }"
                     :target="'_blank'">
          <span class="material-icons-outlined monitor-icon">
            monitor
          </span>
        </router-link>
        <span class="material-icons-outlined" v-on:click="deleteContract(contract)"
          style="color: lightcoral; cursor: pointer;" v-if="(contract.cnt_contas_atrasada > 0)">delete</span>
        <!-- <span class="material-icons-outlined" v-else>edit</span> -->
      </div>
    </div>

    <cc-paginacao class="contract-pagination" :items_by_page_value="50" :total="total" :page="page" :page_limit="11"
      classes="orange" v-on:change="newPage($event)">
    </cc-paginacao>

    <cc-loader-full v-if="isLoading" />
  </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputV2 from '@/components/ui/inputs/StandardInputV2.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import Paginacao from "@/components/cliente/base-components/Pagination";
import contractService from '../../services/v3/contractsService';
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
  mixins: [ perm_mixin ],
  data() {
    return {
      status: [{ text: 'TODOS', value: undefined }, { text: 'NÃO FATURA', value: 'NÃO FATURA' }, { text: 'ATIVO', value: "A" }, { text: 'INATIVO', value: "I" }],
      contractService: new contractService(),
      contracts: [],
      isLoading: true,
      selectedStatus: undefined,
      selectedDocument: undefined,
      page: 1,
      total: 0,
      pendecieOnly: true
    }
  },
  components: {
    StandardInput,
    StandardInputV2,
    StandardButton,
    ccPaginacao: Paginacao,
  },
  created() {
    this.load()
    console.log(this.contracts)
  },
  watch: {
    pendecieOnly() {
      this.isLoading = true
      this.load()
    }
  },
  methods: {
    deleteContract(contract) {
      this.$swal.fire({
        title: "Confirma exclusão da pendência?",
        text: "Esta pendência será excluido.",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, excluir pendência!"
      }).then(resp => {
        if (resp.isConfirmed) {
          this.isLoading = true
          this.contractService.deleteContract(contract).then(() => {
            this.load()
          })
        }
      })
    },
    load() {
      const payload = {
        page: this.page,
        document: this.selectedDocument,
        status: this.selectedStatus,
        pendecie: this.pendecieOnly
      }
      this.contractService.allContracts(payload).then(response => {
        response.data.data.forEach(element => {
          if (element.cnt_status == "A") element.cnt_status = "ATIVO"
          else if (element.cnt_status == "I") element.cnt_status = "INATIVO"
        })
        this.contracts = response.data.data
        this.total = response.data.total
        this.isLoading = false
      })
    },
    newPage(value) {
      this.page = value
      this.isLoading = true
      this.load()
    },
    getStatusStyle(value) {
      if (value == 'ATIVO') return 'contract-a'
      else if (value == 'INATIVO') return 'contract-i'
    },
    clean() {
      this.selectedStatus = undefined
      this.selectedDocument = undefined
      this.isLoading = true
      this.pendecieOnly = false
      this.load()
    },

    setSelectedStatus(value) {
      this.isLoading = true
      this.selectedStatus = value
      this.load()
    },
    formatDate(date) {
      if (date) return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
      else return '--'
    },
    getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    setDocument (value) { 
      this.selectedDocument = value.replace('.','').replace('(','').replace(')','').replace('-','').replace(' ','').replace(' ','').replace('/','')
      if (!this.isLoading) {
        this.isLoading = true
        setTimeout(() => {
          this.load()
        }, 2000);
      }
    },
  }
}
</script>

<style scoped>
.contract-buttons {
  display: flex;
  margin-top: 2.5vh;
  align-items: center;
}

.contract-document-input {
  width: 25%;
}

.contract-status-input {
  width: 25%;
  margin-left: 5%;
}

.contract-clean-input {
  margin-left: auto;
}

.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  align-items: center;
  padding: 1em 0em;
  margin-top: 5vh;
}

.page-table-header-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1.19em;
  color: #505050;
}

.page-table-row-contracts {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}

.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.table1-mobile-header{display: none;}
.page-table-line-special {
  background: #F7F7F7;
}

.id-width {
  width: 5%;
}

.store-width {
  flex: 1;
}

.name-width {
  width: 25%;
}

.date-width {
  width: 12%;
}

.actions-width {
  width: 10%;
}

.pendencie-width {
  width: 10%;
}

.status-width {
  width: 12%;
}

.contract-status {
  border: lightgray 1px solid;
  border-radius: 8px;
  width: fit-content;
  padding: 0 10%;
}

.contract-a {
  background-color: lightgreen;
  color: white;
}

.contract-i {
  color: white;
  background-color: lightcoral;
}

.contract-pagination {
  margin-top: 2vh;
  margin-bottom: 7vh;
}

.contract-pendencies-icon {
  background-color: lightcoral;
  width: fit-content;
  padding: 0 1.5vw;
  color: white;
  border-radius: 8px;
}

.contract-check-container {
  display: flex;
  align-items: center;
  margin-left: 5%;
  gap: 4%;
  cursor: pointer;
}

.contract-check-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}

.contract-checked-icon {
  color: var(--primary-color);
  font-size: 35px;
}

.contract-unchecked-icon {
  color: lightgray;
  font-size: 35px;
}
.monitor-icon {
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 10px;
}


@media only screen and (max-width: 900px) {
  .page-table-header {
    display: none;
  }
  .table1-mobile-header{
    display: flex;
    font-weight: 700;
    font-size: 1.1em;
  }
  
  .page-table-row-contracts {
    flex-direction: column;
    margin-top: 6vh;
    font-size: 1.5em;
  }

  .id-width {
    width: 100%;
  }

  .store-width {
    flex: unset;
    width: 100%;
  }

  .name-width {
    width: 100%;
  }

  .date-width {
    width: 100%;
  }

  .actions-width {
    width: 100%;
  }

  .pendencie-width {
    width: 100%;
  }

  .status-width {
    width: 100%;
  }

  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
}

@media only screen and (max-width: 700px) {
  .contract-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .contract-document-input {
    width: 100%;
    margin-bottom: 2vh;
  }

  .contract-status-input {
    margin-left: 0;
    margin-bottom: 2vh;
    width: 100%;
  }

  .contract-check-container {
    width: 100%;
    margin-left: 0;
  }

  .contract-clean-input {
    margin-left: 0;
    margin-top: 3vh;
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .contract-page {
    font-size: 3vw;
    margin-right: 5vw;
  }

  .contract-clean-input {
    width: 100%;
  }
}
</style>